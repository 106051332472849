import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";

const Background = tw(Img)`
  w-full mb-50
  md:mb-100
  lg:left-1/2 lg:w-1300 lg:mb-200 lg:transform lg:-translate-x-1/2
`;

const DroidsOnRoidsBackground = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "cases/droids-on-roids-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <motion.div ref={Ref} style={ width >= 1024 && { opacity }}>
      <Background fluid={data.background.childImageSharp.fluid} />
    </motion.div>
  );
};

export default DroidsOnRoidsBackground;
