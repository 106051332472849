import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import LayoutPortfolio from "@layouts/portfolio";
import SEO from "@components/seo";
import DroidsOnRoidsHeader from "@components/cases/droids-on-roids/header";
import DroidsOnRoidsContent from "@components/cases/droids-on-roids/content";
import DroidsOnRoidsBackground from "@components/cases/droids-on-roids/background";

const DroidsOnRoidsPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <LayoutPortfolio
      width={width}
      scroll={scrollY}
      name={'droids-on-roids'}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Droids on Roids' })}
        description={intl.formatMessage({ id: 'SEO.Description.Droids on Roids' })}
      />
      <DroidsOnRoidsHeader width={width} scroll={scrollY} />
      <DroidsOnRoidsContent width={width} scroll={scrollY} />
      <DroidsOnRoidsBackground width={width} scroll={scrollY} />
    </LayoutPortfolio>
  );
};

export default DroidsOnRoidsPage;
