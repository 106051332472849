import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const Wrapper = tw.div`
  ml-40 mr-50 mb-30
  sm:flex
  lg:mx-0 lg:mb-50
`;

const Header = tw(motion.div)`
  mb-20 leading-18 -tracking-021
  sm:w-5/12
  lg:relative lg:w-1/2 lg:text-2xl lg:leading-60 lg:-tracking-024
`;

const DescriptionWrapper = tw(motion.div)`
  flex flex-col
  sm:w-6/12 sm:mt-10 sm:ml-auto
  lg:relative lg:w-4/12 lg:ml-140
`;

const Description = tw.p`
  flex text-sm text-second leading-20 -tracking-021
  md:-tracking-027
  lg:w-420 lg:text-md lg:leading-30
`;

const DescriptionCharacter = tw.span`
  font-pangramBold mr-15 lowercase leading-32
  md:-tracking-03
  lg:text-portfolioText lg:text-lg
`;

const DroidsOnRoidsContent = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const top = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [150, 0]);
  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [120, 0]);
  const rotate = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Header style={ width >= 1024 && { top, rotate, opacity }}>
        {t('Cases:DroidsOnRoids:Description')}
      </Header>
      <DescriptionWrapper style={ width >= 1024 && { left, opacity }}>
        <Description>
          <DescriptionCharacter>
            {t('Description Character')}
          </DescriptionCharacter>
          {t('Cases:DroidsOnRoids:Meaning')}
        </Description>
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default DroidsOnRoidsContent;
