import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";
import ArrowSecondary from "@components/arrows/secondary";
import ArrowRed from "@components/arrows/red";

const Wrapper = tw.div`
  relative mt-26 ml-40 mr-30 mb-80
  sm:flex sm:flex-wrap sm:mt-30 sm:mb-150
  lg:mt-225 lg:mb-215
`;

const Info = styled(motion.div)`
  ${tw`
    mb-20 text-sm text-second leading-18 -tracking-021
    sm:order-2 sm:w-320 sm:mt-20 sm:ml-auto
    lg:relative lg:w-500 lg:ml-110 lg:text-primary lg:text-2xl lg:leading-52 lg:-tracking-024
    xl:w-710 xl:mt-80
  `}
  
  br {
    ${tw`hidden lg:block`}
  }
`;

const Logo = tw(motion.div)`
  w-full w-155 mb-26
  sm:order-3 sm:w-180 sm:-mt-80 sm:ml-250
  md:w-200 md:-mt-120 md:ml-375
  lg:w-400 lg:mt-30 lg:ml-435 lg:mb-0
`;

const Team = tw(Img)`w-full`;

const TeamWrapper = tw(motion.div)`
  relative max-w-320 mx-auto
  sm:order-1 sm:max-w-none sm:w-1/3 sm:mx-0
  lg:w-325
`;

const DefaultArrow = styled.div`
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const ArrowWrapper = tw(DefaultArrow)`
  absolute -bottom-30 -left-40 w-100 transform -scale-y-100
  lg:-bottom-45 lg:-left-50 lg:w-150
`;

const ArrowSecondaryWrapper = tw(DefaultArrow)`
  hidden transform rotate-55
  sm:block sm:absolute sm:-bottom-160 sm:-left-50 sm:w-80
  lg:-bottom-240 lg:-left-180 lg:w-160
`;

const ArrowRedWrapper = tw(DefaultArrow)`
  hidden
  sm:block sm:absolute sm:top-130 sm:-right-80 sm:w-120
  lg:top-300 lg:-right-290 lg:w-300
`;

const DotsWrapper = styled.div`
  ${tw`
    absolute -bottom-90 -right-60 w-220 transform -rotate-45
    sm:-bottom-60 sm:-right-60 sm:w-200
    lg:-bottom-90 lg:-right-90 lg:w-260
  `}

  g:nth-child(1), g:nth-child(2), g:nth-child(5), g:nth-child(6) {
    ${tw`hidden`}
  }
`;

const DroidsOnRoidsHeader = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "cases/droids-on-roids.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      team: file(relativePath: { eq: "cases/droids-on-roids-team.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [120, 0]);
  const scale = useTransform(scroll, [sectionStart + 600, sectionStart + 950], [0, 1]);
  const rotate = useTransform(scroll, [sectionStart + 600, sectionStart + 950], [-8, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Logo style={ width >= 1024 && { scale, rotate }}>
        <Img fluid={data.logo.childImageSharp.fluid} />
      </Logo>
      <Info style={ width >= 1024 && { opacity, left }}>
        {t('Cases:DroidsOnRoids:More info1')}
        <br/>
        {t('Cases:DroidsOnRoids:More info2')}
      </Info>
      <TeamWrapper style={ width >= 1024 && { opacity }}>
        <Team fluid={data.team.childImageSharp.fluid} />
        <ArrowWrapper>
          <ArrowPrimary />
        </ArrowWrapper>
        <DotsWrapper>
          <DetailsLarge />
        </DotsWrapper>
      </TeamWrapper>
      <ArrowSecondaryWrapper>
        <ArrowSecondary />
      </ArrowSecondaryWrapper>
      <ArrowRedWrapper>
        <ArrowRed />
      </ArrowRedWrapper>
    </Wrapper>
  );
};

export default DroidsOnRoidsHeader;
